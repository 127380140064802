import { ICMEnvironment } from 'content-manager/src/environments/environment.model';

import { environment as base } from './environment.base';

export const environment: ICMEnvironment = {
    ...base,
    production: true,
    portal: 'scientific',
    apiUrl: 'https://api.scientific.net',
    publicBaseUrl: 'https://www.scientific.net',
    mainBaseUrl: 'https://main.scientific.net',
    internalBaseUrl: 'https://admin.scientific.net',
    identityProviderUrl: 'https://idp.scientific.net',
    editingToolBaseUrl: '',
    contentManagerBaseUrl: '',
    promotionBaseUrl: '',
    apiCmUrl: 'https://cm.scientific.net'
};
